:root {
  --mnk-dark: #221f20; /* Define the variable with your light color */
  --mnk-light: #f1efea; /* Define the variable with your light color */
  --mnk-gray: #dddad4; /* Define the variable with your light color */
  --orange: #c76f31;
  --grey: #7e88a5;
  --olive: #a79f8c;
}

@font-face {
  font-family: "Apercu_Light";
  src: url("../../public/fonts/apercu-light-pro-webfont.woff") format("woff"),
    url("../../public/fonts/apercu-light-pro-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "Apercu_Bold";
  src: url("../../public/fonts/FontsFree-Net-ApercuRegular.ttf") format("woff"),
    url("../../public/fonts/FontsFree-Net-ApercuRegular.ttf") format("woff2");
}

@font-face {
  font-family: "GT-Pressura-Mono";
  src: url("../../public/fonts/gt-pressura-mono-text-webfont.woff") format("woff"),
    url("../../public/fonts/gt-pressura-mono-text-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "Arizona_Flare_Thin";
  src: url("../../public/fonts/abcarizonaflare-thin-webfont.woff") format("woff"),
    url("../../public/fonts/abcarizonaflare-thin-webfont.woff2") format("woff2");
}

* {
  font-family: "Apercu_Light";
  color: var(--mnk-dark);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Arizona_Flare_Thin"; /* Apply the font-family to all header elements */
}

h3 {
  font-family: "Arizona_Flare_Thin", serif;
  margin-top: 0.3rem !important;
  font-size: 1.2rem !important;
  letter-spacing: -0.025em !important;
}

p {
  font-size: 1.1rem !important;
}

.bold {
  font-family: "Apercu_Bold";
}

path {
  color: var(--orange);
}

path-secondary {
  color: var(--mnk-light);
}

p.footer-title {
  color: var(--mnk-light);
}

.a {
  font-family: "Pressura Mono";
}
#root {
  background-color: var(--mnk-gray); /* Use the variable for background color */
}

.footer-title {
  opacity: 1 !important;
}

.select.select-bordered.w-full.bg-none.text-black {
  align-items: center !important;
}

.select.select-bordered.w-full.bg-none.text-black {
  align-items: center !important;
}

/* .rmsc {
  --rmsc-main: rgb(189, 147, 249) !important;
  --rmsc-hover: rgb(40, 42, 54) !important;
  --rmsc-selected: #641ae6;
  --rmsc-border: rgb(189, 147, 249) !important;
  --rmsc-gray: #aaa;
  --rmsc-bg: rgb(40, 42, 54) !important;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 38px;
} */

tr:last-child td {
  /* border: none; */
}

.table tbody tr.highlighted {
  opacity: 0.5;
  background-color: pink; /* Adjust this color to your preference */
  color: white;
}

.table :where(tbody th, tbody td) {
  --tw-bg-opacity: 0 !important;
}

.btn {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-weight: 200;
  display: block;
  min-width: 14.5rem !important;
  height: 3.5rem !important;
  appearance: none;
  border: 0;
  border-top-left-radius: 2em !important; /* Top left corner curved */
  border-top-right-radius: 2em !important; /* Top right corner curved */
  border-bottom-left-radius: 0em !important; /* Top left corner curved */
  border-bottom-right-radius: 0em !important;
  font-size: 1.4rem;
  text-align: center;
  outline: 0;
  color: #231f20;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #c76f31;
  text-transform: uppercase;
  cursor: pointer;
  font-family: GT-Pressura-Mono, serif;
  letter-spacing: -0.025em !important;
  color: #231f20 !important; /* Black text color */
  font-weight: unset !important;
}

.btn.btn-secondary.text-neutral:hover {
  background-color: #a76ca1; /* Change to your desired hover background color */
  color: #ffffff; /* Change to your desired hover text color */
}

th {
  background-color: var(--olive) !important;
  color: var(--mnk-light) !important;
}

.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  border-left-color: var(--olive) !important;
}

.textarea {
  min-height: 110px !important; /* Set the maximum height */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds the maximum height */
}

/* Signin.css */
.logo {
  height: 82px;
  animation: fadeIn 2s ease;
}

.title {
  font-family: Arizona_Flare_Thin;
  animation: slideIn 2s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
}

.anim--top {
  top: 0;
  left: 0;
}

.anim--bottom {
  bottom: 0;
  right: 0;
  transform: scaleY(-1);
}

.anim--top,
.anim--bottom {
  /* max-width: 100%; */
  max-height: 10%;
}

.content {
  font: inherit;
  font-size: 100%;
  line-height: 1;
  font-family: Apercu, sans-serif;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  position: relative;
  background: #a89f8a;
  min-height: 100vh;
  /* padding: 60vw 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-aspect-ratio: 1 / 1) {
  .content {
    padding: 10rem 0;
  }
}

@media (min-aspect-ratio: 1 / 1) {
  .anim--left,
  .anim--right {
    display: block;
  }
}
.anim--left {
  display: none;
  top: 0;
  left: 0;
}
.anim--right {
  display: none;
  top: 0;
  right: 0;
  transform: scaleX(-1);
}

.anim--left,
.anim--right {
  max-width: 50%;
  height: 100%;
}
.anim {
  position: absolute;
  pointer-events: none;
  /* overflow: hidden; changed this*/
}

@media (min-aspect-ratio: 1 / 1) {
  .content {
    padding: 10rem 0;
  }
}

@media (min-aspect-ratio: 1 / 1) {
  .anim--left,
  .anim--right {
    display: block;
  }
}

body {
  /* margin: 0; */
  line-height: inherit;
}

@media (min-aspect-ratio: 1 / 1) {
  .anim--top,
  .anim--bottom {
    display: none;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  margin-top: auto;
  z-index: 1;
}

.pulse-icon {
  width: 15px; /* Adjust size as needed */
  height: 15px; /* Adjust size as needed */
  background-color: var(--orange); /* Adjust color as needed */
  border-radius: 50%;
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}

button.btn.btn-secondary.text-neutral[disabled] {
  background-color: var(--mnk-light);
}

.badge {
  font-size: 16px !important;
  padding: 12px !important;
}

/* Pulse animation for active status */
.pulse-icon {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .breadcrumbs {
    margin-bottom: 1rem;
  }

  .breadcrumbs ul {
    padding: 0;
  }
}

/* Card hover effects */
.bg-base-200 {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.bg-base-200:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Loading animation */
.loading {
  opacity: 0.7;
}

/* Sort menu transition */
.sort-menu {
  transition: all 0.3s ease-in-out;
}
